module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-9b4088300f/4/root/.yarn/berry/cache/gatsby-plugin-catch-links-npm-5.13.1-d0715a6524-10c0.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-076c3cfd75/4/root/.yarn/berry/cache/gatsby-remark-autolink-headers-npm-6.13.1-0091404466-10c0.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-0ac7753fdd/4/root/.yarn/berry/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":760,"srcSetBreakpoints":[760],"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-380c1c7f4c/4/root/.yarn/berry/cache/gatsby-plugin-next-seo-npm-1.11.0-9def787574-10c0.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"en","openGraph":{"description":"Cookie Collective gathers digital artists involved in real-time creation. This covers video games, art installations, video mapping, demoscene, live coding, etc.","images":[{"url":"https://cookie.paris/image.jpg"}],"site_name":"Cookie Collective","type":"website","url":"https://cookie.paris"},"titleTemplate":"%s | Cookie Collective","twitter":{"cardType":"summary","site":"@CookieDemoparty"}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-0ac7753fdd/4/root/.yarn/berry/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-10c0.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-252911be6c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
